import React from "react";
import { useTranslation } from "react-i18next";
import logo from "assets/logo/logo.png";

const TitleBar = ({ title, pageOptions }) => {
  const { t } = useTranslation();
  return (
    <div className="title-bar">
      <div className="d-flex align-items-center ">
        <img
          src={logo}
          alt="Unified Transport & e-Logistics"
          className="app-logo"
        />
        {title && <strong className="title-label">{t(title)}</strong>}
      </div>
      <div className="d-flex align-items-center ">{pageOptions}</div>
    </div>
  );
};

export default TitleBar;
