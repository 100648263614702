export const digitsToCurrency = (digits) => {
  let a = parseFloat(digits ?? 0);
  let opposite = parseFloat(digits ? digits * -1 : 0);
  const currency =
    digits < 0
      ? `(ETB ${opposite.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "ETB &,")})`
      : "ETB " + a.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return currency;
};

export const numberShortFormat = (num = 0, digits = 1) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};
