import React, { useEffect, useState } from "react";
import TableTrailers from "./TableTrailers";
import { getElements } from "services/apiEndpoints/Endpoints";
import Loading from "components/loading/Loading";

import TrailerFilters from "./TrailersFilters";
import useDebounce from "utils/useDebounce";
import TitleBar from "layout/top-menu-bar/TitleBar";

const Trailers = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(Loading);
  const [request, setRequest] = useState({
    status: "",
    from_utc: "",
    to_utc: "",
    limit: 50,
    offset: 1,
  });
  const [trailerFilter, setTrailerFilter] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceSearch = useDebounce(searchTerm, 500);

  const getVehicles = () => {
    setLoading(Loading);
    const queryStr = new URLSearchParams({
      ...request,
      status: request.status.replaceAll(" ", ""),
    }).toString();
    getElements("Authority/trailer?" + queryStr).then((res) => {
      setVehicles(res.data);
      setLoading(false);
    });
  };

  useEffect(getVehicles, [request]);

  useEffect(() => {
    if (debounceSearch) {
      let newFilterTrailer = vehicles.filter((item) =>
        (item.carrierName || "")
          .toLowerCase()
          .includes(debounceSearch.toLowerCase())
      );
      console.log(newFilterTrailer);
      setTrailerFilter(newFilterTrailer);
    } else {
      setTrailerFilter(vehicles);
    }
  }, [debounceSearch, vehicles]);

  return (
    <div>
      <TitleBar
        title={"Trailers"}
        pageOptions={
          <>
            <div
              style={{
                border: "1px solid #6B7280",
                borderRadius: "5px",
                width: "300px",
              }}
            >
              <i
                className="mil-search ml-2 mr-2 "
                style={{ color: "#6B7280" }}
              />
              <input
                type="text"
                placeholder="Search By Carrier"
                style={{ border: "none", outline: "none", width: "260px" }}
                className="py-1"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="ml-5">
              <TrailerFilters request={request} setRequest={setRequest} />
            </div>
          </>
        }
      />

      {loading ? (
        loading
      ) : (
        <>
          <TableTrailers
            vehicles={trailerFilter}
            getVehicles={getVehicles}
            updateVehiclesNDashboard={() => {
              getVehicles();
            }}
            request={request}
            setRequest={setRequest}
          />
        </>
      )}
    </div>
  );
};

export default Trailers;
