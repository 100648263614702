import DataTable from "react-data-table-component";
import statusColumn from "./statusCulumn";
import expandedStatusColumn from "./ExpandedStatusTableColumn";
import { getElements } from "services/apiEndpoints/Endpoints";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EmaButton from "components/emaButton/EmaButton";
import { CSVLink } from "react-csv";
import dateFormatter from "utils/dateFormater";

const StatusTable = ({ laneType }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const Getstatus = () => {
    getElements(`reporting/commodity/status?lane_type=${laneType}`).then(
      (res) => {
        setExpanded(res.data);

        let _csvData = [];
        (res.data || []).forEach((com) => {
          let {
            commodity_type: commodity,
            shipping_cleared_total: shipping_cleared,
            under_customs_total: under_customs,
            customs_cleared_total: customs_cleared,
            tender_total: tender,
            booked_total: booked,
            inprogress_total: inprogress,
            delivered_total: delivered,
          } = com;

          _csvData.push({
            commodity,
            shipping_cleared,
            under_customs,
            customs_cleared,
            tender,
            booked,
            inprogress,
            delivered,
          });
          _csvData.push(
            ...(com.commodities || []).map((c) => ({
              ...c,
              commodity: ` ${commodity || ""}`,
            }))
          );
        });

        setCsvData(_csvData);
      }
    );
  };
  useEffect(Getstatus, [laneType]);

  const ExpandedComponent = ({ data }) => {
    return (
      <div className="ema-table short-table">
        <DataTable
          data={data.commodities}
          columns={expandedStatusColumn}
          noTableHead
          noHeader
          customStyles={{
            rows: {
              style: {
                background: "#f2f2f2",
              },
            },
          }}
        />
      </div>
    );
  };

  const CSVheader = [
    { label: "Commodity", key: "commodity" },
    { label: "Shipping Cleared", key: "shipping_cleared" },
    { label: "Under Customs ", key: "under_customs" },
    { label: "Customs Cleared", key: "customs_cleared" },
    { label: "Tender", key: "tender" },
    { label: "Booked", key: "booked" },
    { label: "Inprogress", key: "inprogress" },
    { label: "Delivered", key: "delivered" },
  ];
  return (
    <div className="operational-display">
      <div className="title-bar">
        <strong className="title-label">{t(`${laneType || ""} Status`)}</strong>
        <div className="d-flex align-items-center">
          <CSVLink
            data={csvData}
            headers={CSVheader}
            filename={`${laneType}Status-${dateFormatter(
              Date(),
              "YYYY-MM-DD-HH:mm"
            )}(FIEP).csv`}
            className="ema-btn btn-ema-dark mr-4"
            target="_blank"
          >
            Download Report
          </CSVLink>
        </div>
      </div>
      <div className="summary-list">
        <DataTable
          columns={statusColumn.map((c) => ({
            ...c,
            name: t(c.name),
          }))}
          data={expanded}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default StatusTable;
