import { useState } from "react";
import DataTable from "react-data-table-component";
import SliderPanel from "components/sliderPanel/SliderPanel";
import dateFormatter from "utils/dateFormater";
import AuthorityForm from "../operatorDetails/authorities/AuthorityForm";
import CellBuilder from "components/cell-builder/CellBuilder";
import BorderedText from "components/bordered-text/BorderedText";
import { toast } from "react-toastify";

const AuthorityCard = ({ authorities, setAuthorities }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onSave = (auth) => {
    if (!auth.authorityType) {
      toast.error("Authority Type is required!");
    } else {
      const modifiedAuthorities = JSON.parse(JSON.stringify(authorities));

      //editng existing authority
      const foundDuplicateIndex = authorities.findIndex(
        (a) => a.authorityType == auth.authorityType
      );
      if (foundDuplicateIndex >= 0) {
        modifiedAuthorities.splice(foundDuplicateIndex, 1, auth);
      }
      //adding new authority
      else {
        modifiedAuthorities.push({
          ...auth,
          documents: [],
        });
      }

      setAuthorities(modifiedAuthorities);
      setIsOpen(false);
    }
  };

  const colums = [
    {
      name: "Authority Type",
      selector: (row) => CellBuilder(row.authorityType),
      grow: 2,
    },

    {
      name: "Authority Start Date",
      selector: (row) =>
        CellBuilder(dateFormatter(row.authorityStartDate, "DD MMM YYYY")),
      grow: 2,
    },

    {
      name: "Status",
      grow: 2,
      selector: (row) =>
        CellBuilder(
          <BorderedText
            text={row.status}
            classes={(row.status || "").replaceAll(" ", "")}
          />
        ),
    },
    {
      name: "Action",
      grow: 1,
      cell: (row) => {
        return (
          <div className="table-actions">
            <i
              className={`icon-edit clickable `}
              onClick={() => {
                setIsOpen(row);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="ema-card">
      <div className="ema-card-title d-flex align-items-center justify-content-between">
        <div>Operating Authorities</div>
        <i
          className={`mil-plus clickable`}
          onClick={() => setIsOpen({})}
          style={{ fontSize: "20px" }}
        />
      </div>
      <div className="summary-list">
        <DataTable columns={colums} data={authorities} />
      </div>

      <SliderPanel setIsOpen={setIsOpen} isOpen={isOpen} from="bottom">
        <AuthorityForm
          authority={isOpen}
          handleOnSave={onSave}
          setIsOpen={setIsOpen}
        />
      </SliderPanel>
    </div>
  );
};

export default AuthorityCard;
