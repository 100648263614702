import DataTable from "react-data-table-component";
import CellBuilder from "components/cell-builder/CellBuilder";
import "../../assets/styles/summary-list-tables.sass";
import { useState } from "react";
import SliderPanel from "components/sliderPanel/SliderPanel";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import joinStrings from "utils/joinStrings";
import EditOperator from "./EditOperator";
import BorderedText from "components/bordered-text/BorderedText";
import NewVehicle from "view/vehicles/NewVehicle";
import FleetOwners from "./FleetOwners";
import CustomPagination from "components/custom-pagination/CustomPagination";
import { useTranslation } from "react-i18next";
import NewTrailer from "view/trailers/NewTrailer";

function Table({ operators, getOperators, request, setRequest }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [openVehicleRegistration, setOpenVehicleRegistration] = useState(false);
  const [openEquipmentRegistration, setOpenEquipmentRegistration] = useState(
    false
  );
  const [openFleetOwners, setOpenFleetOwners] = useState(false);

  const columns = [
    {
      name: "Company",
      selector: (row) => CellBuilder(row.company, row.dba, row.companyLogoURL),
      grow: 2,
    },
    {
      name: "Authority Type",
      selector: (row) => CellBuilder(row.authorityType, row.operatorId),
      grow: 2,
    },

    {
      name: "Address",
      grow: 2,
      selector: (row) => {
        return CellBuilder(
          row.addressLine1,
          joinStrings([row.city, row.state], ", ")
        );
      },
    },

    {
      name: "Status",
      grow: 2,
      selector: (row) =>
        CellBuilder(
          <BorderedText
            text={row.status}
            classes={(row.status || "").replaceAll(" ", "")}
          />
        ),
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="table-actions">
            <i
              className="icon-details"
              style={{ cursor: "pointer" }}
              onClick={() => setIsOpen(row.operatorId)}
            />

            <ElipsisMenu>
              {(row.authorityType || "").toLowerCase().includes("carrier") && (
                <>
                  <MenuItem
                    title={t("Register Vehicle")}
                    onClick={() => setOpenVehicleRegistration(row)}
                    targetActionDenied={
                      row.status === "Pending Authorization" ||
                      row.status === "Revoke"
                    }
                  />
                  <MenuItem
                    title={t("Register Trailer")}
                    onClick={() => setOpenEquipmentRegistration(row)}
                    targetActionDenied={
                      row.status === "Pending Authorization" ||
                      row.status === "Revoke"
                    }
                  />
                  <MenuItem
                    title={t("Add Fleet Owner")}
                    onClick={() => setOpenFleetOwners(row.operatorId)}
                    targetActionDenied={
                      row.status === "Pending Authorization" ||
                      row.status === "Revoke"
                    }
                  />
                </>
              )}

              <MenuItem
                title={t("Edit Authority")}
                onClick={() => setIsOpen(row.operatorId)}
              />
            </ElipsisMenu>
          </div>
        );
      },
    },
  ];
  return (
    <div className="summary-list ">
      <DataTable
        columns={columns.map((c) => ({
          ...c,
          name: t(c.name),
        }))}
        data={operators}
      />
      <CustomPagination
        setRequest={setRequest}
        request={request}
        nextExists={operators?.length >= request.limit}
      />
      <SliderPanel setIsOpen={() => {}} isOpen={openVehicleRegistration}>
        <NewVehicle
          setIsOpen={setOpenVehicleRegistration}
          operator={openVehicleRegistration}
          getVehicles={() => {}}
        />
      </SliderPanel>
      <SliderPanel setIsOpen={() => {}} isOpen={openEquipmentRegistration}>
        <NewTrailer
          setIsOpen={setOpenEquipmentRegistration}
          operator={openEquipmentRegistration}
          getVehicles={() => {}}
        />
      </SliderPanel>
      <SliderPanel setIsOpen={() => {}} isOpen={openFleetOwners}>
        <FleetOwners
          setIsOpen={setOpenFleetOwners}
          id={openFleetOwners}
          getOperators={getOperators}
        />
      </SliderPanel>
      <SliderPanel setIsOpen={setIsOpen} isOpen={isOpen}>
        <EditOperator
          setIsOpen={setIsOpen}
          id={isOpen}
          getOperators={getOperators}
        />
      </SliderPanel>
    </div>
  );
}

export default Table;
