import DateTimeInput from "components/datetime-input/DateTimeInput";
import EmaButton from "components/emaButton/EmaButton";
import EmaInput from "components/emaInput/EmaInput";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import findError from "utils/findError";

const AuthorityForm = ({ authority, handleOnSave, setIsOpen, disableType }) => {
  const { t } = useTranslation();

  const [state, setState] = useState(authority || {});

  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }
  return (
    <div>
      <div className="slider-header">
        <section className="slider-header-title">{t("Authority Form")}</section>
        <section className="slider-header-buttons">
          <EmaButton title={t("Cancel")} onClick={() => setIsOpen(false)} />
          <EmaButton
            title={t("Save")}
            btnDark
            onClick={() => handleOnSave(state)}
          />
        </section>
      </div>
      <div className="slider-body">
        <div className="ema-card">
          <div className="row">
            <div className="col col-6">
              <EmaInput
                type="select"
                options={["Carrier", "Forwarder", "Shipper"]}
                name="authorityType"
                label={t("Authority Type")}
                value={state.authorityType}
                onChange={handleChange}
                error={findError("authorityType")}
                disabled={disableType}
                required
              />
            </div>
            <div className="col col-6">
              <DateTimeInput
                type="date"
                value={state.authorityStartDate}
                onChange={handleChange}
                name="authorityStartDate"
                label={t("Authority Start Date")}
                error={findError("authorityStartDate")}
              />
            </div>
            <div className="col col-6">
              <EmaInput
                name="status"
                label={t("Status")}
                value={state.status || "Pending Authorization"}
                onChange={handleChange}
                error={findError("status")}
                disabled={true}
              />
            </div>

            <div className="col col-6">
              <EmaInput
                type="textarea"
                name="note"
                label={t("Note")}
                value={state.note}
                onChange={handleChange}
                error={findError("note")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorityForm;
