import React, { useEffect, useState } from "react";
import MarketplaceTable from "./MarketplaceTable";
import { getElements } from "services/apiEndpoints/Endpoints";
import Loading from "components/loading/Loading";
import MarketplaceFilter from "./MarketplaceFilter";
import { useTranslation } from "react-i18next";
import { data } from "jquery";
import useDebounce from "utils/useDebounce";
import TitleBar from "layout/top-menu-bar/TitleBar";

const Marketplace = () => {
  const [marketplace, setMarketplace] = useState([]);
  const [loading, setLoading] = useState(Loading);
  const [request, setRequest] = useState({
    tender_type: "",
    trailer_type: "",
    available_from: "",
    available_to: "",
    origin: "",
    destination: "",
    is_quote: "",
    limit: 50,
    offset: 1,
  });

  const [filterMarketplace, setFilterMarketplace] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceSearch = useDebounce(searchTerm, 500);

  const getMarketplace = () => {
    setLoading(Loading);
    const querystr = new URLSearchParams({
      ...request,
      tender_type: request.tender_type.replaceAll(" ", ""),
      trailer_type: request.trailer_type.replaceAll(" ", ""),
    }).toString();
    getElements("Marketplace?" + querystr).then((res) => {
      setMarketplace(res.data);
      setLoading(false);
    });
  };

  useEffect(getMarketplace, [request]);
  const { t } = useTranslation();

  useEffect(() => {
    if (debounceSearch) {
      let newFilterMaketplace = marketplace.filter((item) =>
        (item.company || "")
          .toLowerCase()
          .includes(debounceSearch.toLowerCase())
      );
      console.log(newFilterMaketplace);

      setFilterMarketplace(newFilterMaketplace);
    } else {
      setFilterMarketplace(marketplace);
    }
  }, [debounceSearch, marketplace]);

  return (
    <div>
      <TitleBar
        title={"Marketplace"}
        pageOptions={
          <>
            <div
              style={{
                border: "1px solid #6B7280",
                borderRadius: "5px",
                width: "300px",
              }}
            >
              <i
                className="mil-search ml-2 mr-2 "
                style={{ color: "#6B7280" }}
              />
              <input
                type="text"
                placeholder="Search By Company"
                style={{ border: "none", outline: "none", width: "260px" }}
                className="py-1"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="ml-5">
              <MarketplaceFilter
                request={request}
                setRequest={setRequest}
                t={t}
              />
            </div>
          </>
        }
      />

      {loading ? (
        loading
      ) : (
        <MarketplaceTable
          marketplace={filterMarketplace}
          request={request}
          setRequest={setRequest}
        />
      )}
    </div>
  );
};

export default Marketplace;
