const menuRoutes = [
  {
    path: "/dashboard",
    title: "Dashboard",
  },
  {
    path: "/marketplace",
    title: "Marketplace",
  },
  {
    path: "/activity-report",
    title: "Activity Report",
  },
  {
    path: "/freight-operators",
    title: "Freight Operators",
  },

  {
    path: "/vehicles",
    title: "Vehicles",
  },
  {
    path: "/trailers",
    title: "Trailers",
  },
];

export default menuRoutes;
