import { useState } from "react";
import EmaButton from "components/emaButton/EmaButton";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import EmaInput from "components/emaInput/EmaInput";
import findError from "utils/findError";
import { postElement } from "services/apiEndpoints/Endpoints";
import DateTimeInput from "components/datetime-input/DateTimeInput";

export default function AddDocumentModal({ closeModal, docObj, saveDocs }) {
  const [document, setDocument] = useState(docObj);
  const [file, setFile] = useState("");

  const [errors, setErrors] = useState([]);

  const onDocTypeChange = (e) => {
    const { value, name } = e.target || {};
    setDocument({ ...document, [name]: value });
  };

  const fileChange = (e) => {
    let files = e.target.files;
    const formFile = new FormData();
    formFile.append("Document", files[0]);
    setFile(formFile);
  };

  const addDocument = () => {
    if (file) {
      postElement(`/Document?DocumentCategory=Company`, file).then(
        (response) => {
          if (response.success) {
            saveDocs({
              document: { ...document, documentUrl: response.data },
              closeModal,
              setErrors,
            });
          } else if (response.vErrors) {
            setErrors(response.vErrors);
          }
        }
      );
    } else {
      saveDocs({ document, closeModal, setErrors });
    }
  };

  return (
    <div className="">
      <Modal isOpen={true} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Add Document</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col col-6">
              <EmaInput
                label="Document Type"
                name="documentType"
                value={document.documentType}
                disabled
              />
            </div>
            <div className="col col-6">
              <EmaInput
                label="Document Number"
                name="documentNumber"
                value={document.documentNumber}
                onChange={onDocTypeChange}
              />
            </div>

            <div className="col col-6">
              <DateTimeInput
                type="date"
                label="Document Issued Date"
                name="documentIssuedUtc"
                value={document.documentIssuedUtc}
                onChange={onDocTypeChange}
              />
            </div>
            <div className="col col-6">
              <DateTimeInput
                type="date"
                label="Document Expiration Date"
                name="documentExpirationUtc"
                value={document.documentExpirationUtc}
                onChange={onDocTypeChange}
              />
            </div>
          </div>
          <div className="column">
            <EmaInput
              label="File"
              type="file"
              onChange={fileChange}
              error={findError("Document", errors)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <EmaButton
            icon="mil-save-o"
            title="Save"
            onClick={() => {
              addDocument(document);
            }}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
}
