import BorderedText from "components/bordered-text/BorderedText";
import CellBuilder from "components/cell-builder/CellBuilder";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import { deleteElement, putElement } from "services/apiEndpoints/Endpoints";
import dateFormatter from "utils/dateFormater";

const authoritiesColumns = (disableInputs, onEdit, getAuthorities) => [
  {
    name: "Authority Type",
    selector: (row) => CellBuilder(row.authorityType, row.authorityNumber),
    grow: 2,
  },

  {
    name: "Authority Start Date",
    selector: (row) =>
      CellBuilder(dateFormatter(row.authorityStartDate, "DD MMM YYYY")),
    grow: 2,
  },

  {
    name: "Status",
    grow: 2,
    selector: (row) =>
      CellBuilder(
        <BorderedText
          text={row.status}
          classes={(row.status || "").replaceAll(" ", "")}
        />
      ),
  },
  {
    name: "Action",
    grow: 0.5,
    center: true,
    selector: (row) => {
      return (
        <div className="table-actions">
          {!disableInputs && (
            <ElipsisMenu>
              <MenuItem
                title={"Edit Authority"}
                onClick={() => {
                  !disableInputs && onEdit(row);
                }}
              />

              {row.status !== "Authorized" && (
                <MenuItem
                  title={"Authorize"}
                  onClick={() =>
                    putElement(
                      `/Authority/OperatingAuthority/${row.operatingAuthorityId}/Authorize`
                    ).then((response) => {
                      if (response.success) getAuthorities();
                    })
                  }
                />
              )}

              {(row.status === "Authorized" ||
                row.status === "Pending Authorization") && (
                <MenuItem
                  title={"Revoke Authority"}
                  onClick={() =>
                    deleteElement(
                      `/Authority/OperatingAuthority/${row.operatingAuthorityId}`
                    ).then((response) => {
                      if (response.success) getAuthorities();
                    })
                  }
                />
              )}
            </ElipsisMenu>
          )}
        </div>
      );
    },
  },
];

export default authoritiesColumns;
