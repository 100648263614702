import React, { useEffect, useState } from "react";
import TableVehicle from "./TableVehicle";
import { getElements } from "services/apiEndpoints/Endpoints";
import Loading from "components/loading/Loading";

import VehicleFilters from "./VehicleFilters";
import VehicleDashboard from "./vehicle-dashboard/VehicleDashboard";
import useDebounce from "utils/useDebounce";
import TitleBar from "layout/top-menu-bar/TitleBar";

const Vehicles = () => {
  const [vehicles, setVehicles] = useState([]);
  const [vehicleDashboard, setVehicleDashboard] = useState([]);
  const [loading, setLoading] = useState(Loading);
  const [request, setRequest] = useState({
    status: "",
    from_utc: "",
    to_utc: "",
    limit: 50,
    offset: 1,
  });

  const [carrierFilter, setCarrierFilter] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceSearch = useDebounce(searchTerm, 500);

  const getVehicles = () => {
    setLoading(Loading);
    const queryStr = new URLSearchParams({
      ...request,
      status: request.status.replaceAll(" ", ""),
    }).toString();
    getElements("Authority/Vehicle?" + queryStr).then((res) => {
      setVehicles(res.data);
      setLoading(false);
    });
  };
  const getVehicleDashboard = () => {
    getElements(`/dashboard/vehicle/actionable`).then((res) => {
      setVehicleDashboard(res.data);
    });
  };

  useEffect(getVehicles, [request]);
  useEffect(getVehicleDashboard, []);

  useEffect(() => {
    if (debounceSearch) {
      let newFilterCarrier = vehicles.filter((item) =>
        (item.carrierName || "")
          .toLowerCase()
          .includes(debounceSearch.toLowerCase())
      );
      console.log(newFilterCarrier);
      setCarrierFilter(newFilterCarrier);
    } else {
      setCarrierFilter(vehicles);
    }
  }, [debounceSearch, vehicles]);

  return (
    <div>
      <TitleBar
        title={"Vehicles"}
        pageOptions={
          <>
            <div
              style={{
                border: "1px solid #6B7280",
                borderRadius: "5px",
                width: "300px",
              }}
            >
              <i
                className="mil-search ml-2 mr-2 "
                style={{ color: "#6B7280" }}
              />
              <input
                type="text"
                placeholder="Search By Carrier"
                style={{ border: "none", outline: "none", width: "260px" }}
                className="py-1"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="ml-5">
              <VehicleFilters request={request} setRequest={setRequest} />
            </div>
          </>
        }
      />

      {loading ? (
        loading
      ) : (
        <>
          <div>
            <VehicleDashboard response={vehicleDashboard} />
          </div>
          <TableVehicle
            vehicles={carrierFilter}
            getVehicles={getVehicles}
            updateVehiclesNDashboard={() => {
              getVehicleDashboard();
              getVehicles();
            }}
            request={request}
            setRequest={setRequest}
          />
        </>
      )}
    </div>
  );
};

export default Vehicles;
