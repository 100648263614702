import CellBuilder from "components/cell-builder/CellBuilder";
import AddDocumentModal from "components/document/AddDocumentModal";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { getElements } from "services/apiEndpoints/Endpoints";
import dateFormatter from "utils/dateFormater";
import { GrDocumentMissing } from "react-icons/gr";

const AuthorityDocs = ({ data, disableInputs, onSaveDocs }) => {
  const { documents: _document } = data;
  //let disableInputs = true;
  const [contactOpen, setContactOpen] = useState(false);

  const saveDocs = ({ document, closeModal, setErrors }) => {
    const modifiedDocs = _document.map((item) =>
      item.documentType === document.documentType
        ? { ...document, operatingAuthorityId: data.operatingAuthorityId }
        : item
    );

    let requestObj = { ...data, documents: modifiedDocs };
    onSaveDocs(requestObj);
    closeModal();
  };

  const downloadDocument = (url) => {
    getElements(`/Document?documentUrl=${url}`, {
      responseType: "blob",
      timeout: 30000,
    }).then((response) => {
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      let { type } = response.data;
      let fileType = type.substring(type.lastIndexOf("/") + 1);
      let fileName = url.substring(url.lastIndexOf("/") + 1);
      //let fullName = fileName + "." + `${customType ? customType : fileType}`;
      let fullName = fileName + "." + `${fileType}`;

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fullName);
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <>
      <div className="ema-table short-table">
        <DataTable
          data={_document}
          columns={[
            {
              name: "Document",
              selector: (row) =>
                CellBuilder(
                  <div>
                    {row.documentType}{" "}
                    {!row.documentUrl && (
                      <span style={{ color: "red" }}>
                        <GrDocumentMissing />
                      </span>
                    )}
                  </div>,
                  row.documentNumber
                ),
              grow: 4,
            },
            {
              name: "Issued Date",
              selector: (row) =>
                CellBuilder(
                  dateFormatter(row.documentIssuedUtc, "DD MMM YYYY")
                ),
              grow: 2,
            },
            {
              name: "Expiration Date",
              selector: (row) =>
                CellBuilder(
                  dateFormatter(row.documentExpirationUtc, "DD MMM YYYY")
                ),
              grow: 2,
            },

            {
              name: "Actions",
              cell: (row) => {
                return (
                  <div className="table-actions">
                    {row.documentUrl ? (
                      <i
                        className={`icon-arrow-bg-down clickable ${
                          disableInputs && "disabled"
                        }`}
                        onClick={() =>
                          !disableInputs && downloadDocument(row.documentUrl)
                        }
                      />
                    ) : (
                      <i
                        className="icon-arrow-bg-down"
                        style={{ color: "transparent" }}
                      />
                    )}

                    {!disableInputs && (
                      <ElipsisMenu>
                        <MenuItem
                          title="Edit Document"
                          className={`icon-edit clickable ${
                            disableInputs && "disabled"
                          }`}
                          onClick={() => !disableInputs && setContactOpen(row)}
                        />
                        {/* {row.documentUrl && (
                          <MenuItem
                            title="Delete Document"
                            onClick={() => {
                              deleteDoc(row);
                            }}
                          />
                        )} */}
                      </ElipsisMenu>
                    )}
                  </div>
                );
              },
              grow: 2,
            },
          ]}
        />
      </div>
      {contactOpen && (
        <AddDocumentModal
          closeModal={() => setContactOpen(false)}
          docObj={contactOpen}
          contacts={_document}
          getContacts={() => {}}
          saveDocs={saveDocs}
          //postingUrl={`/Authority/Operator/${operatorId}/Document`}
        />
      )}
    </>
  );
};

export default AuthorityDocs;
