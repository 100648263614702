import React from "react";
import "./OperatorDashboard.sass";
import { useTranslation } from "react-i18next";

const OperatorDashboard = ({ response }) => {
  const { t } = useTranslation();
  const data = [
    {
      label: t("Pending Forwarders"),
      value: response?.pendingForwarders,
    },
    {
      label: t("Pending Shippers"),
      value: response?.pendingShippers,
    },
    {
      label: t("Pending Carriers"),
      value: response?.pendingCarriers,
    },
    {
      label: t("Pending Fleet Owners"),
      value: response?.pendingFleetOwners,
    },
    {
      label: t("Total Pending"),
      value: response?.pendingTotal,
    },
  ];
  return (
    <div className="operator-dashboard">
      <>
        {data.map((operator, index) => (
          <section key={index} className="operator-dashboard-card">
            <div className="operator-dashboard-label">{operator.label}</div>
            <div className="operator-dashboard-count">
              {operator.value || 0}
            </div>
          </section>
        ))}
      </>
    </div>
  );
};

export default OperatorDashboard;
